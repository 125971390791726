// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  ressourceUrl: 'http://apidilog.iwajutech.com/storage/app/public/',

  production: false,
  //apiUrl:"http://localhost:8001/api/",
  //apiUrl:"http://locahost:8080/api_ventevoiture_3igroup/api_vente_voiture/public/api/",
   apiUrl: ' https://apiwcvoodoo.iwajutech.com/public/api/admin/',
 //apiUrl:"http://192.168.100.179:8001/api/",
  firebase: { 
    apiKey: "AIzaSyCJi0cQ866lzPR8hYJUs77JUmD4DBpswl8",
    authDomain: "angular-deomo.firebaseapp.com",
    databaseURL: "https://angular-deomo.firebaseio.com",
    projectId: "angular-deomo",
    storageBucket: "angular-deomo.appspot.com",
    messagingSenderId: "175907956497"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
